import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import { useGoogleLogin } from '@react-oauth/google';
import {HOST} from '../../services/baseApi.js';
import FacebookLoginButton from "../buttons/FacebookLoginButton.js";


const LoginForm = (props) => {
    const { t } = useTranslation();
    const login = useGoogleLogin({
        /*onSuccess: async (codeResponse) => {
            props.setShowLoading(true);
            try {
                const result = await fetchLoginGoogle(codeResponse.code);
                props.loginProcess(result);
            } catch (error) {
                fullError(t('anErrorOccurred'), error);
            } finally {
                props.setShowLoading(false);
            }
        },
        onError: codeResponse => {
            props.setShowLoading(false);
            fullError(t('anErrorOccurred'), codeResponse);
        },*/
        flow: 'auth-code',
        ux_mode: 'redirect',
        redirect_uri: `${HOST}/login/google`,
    });
    return (
        <>

            <div className="d-flex flex-column justify-content-center mb-3 mt-2">
                {/* <FacebookLoginButton /> */}

                <button onClick={() => login()} type="button" className="btn btn-lg btn-danger"><i className="bi bi-google float-start ms-2"></i>{t('signInWithGoogle')}</button>

                <span className="or mt-3">- o -</span>
            </div>
            <div className="form-floating">
                <input onChange={(event) => props.setEmailValue(event.target.value)} type="text" className="form-control" id="floatingInput" placeholder={t('email')} />
                <label htmlFor="floatingInput">{t('email')}</label>
            </div>
            <div className="form-floating">
                <input onKeyUp={props.handleKeyUp}
                    onChange={(event) => props.setPasswordValue(event.target.value)} type="password" className="form-control floatingPassword" id="floatingPassword"
                    placeholder={t('password')} />
                <label htmlFor="floatingPassword">{t('password')}</label>
            </div>
            <button onClick={props.login} className="w-100 btn btn-lg btn-primary mb-3" type="button">{t('signIn')}</button>
            <div className="d-flex flex-column justify-content-center">
                <span className="or mb-3">- o -</span>

                <Link to="register" className="w-100 btn btn-lg btn-primary mb-3" type="button">{t('register')}</Link>

            </div>

        </>
    );
};

export default LoginForm;