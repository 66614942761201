
import MenuElem from './MenuElem';

import React, { useEffect, useState } from 'react';
import MenuAdmin from './MenuAdmin';
import { useTranslation } from 'react-i18next';
import { fetchRestaurantData, getRestaurant } from '../../services/api';


function MenuList(props) {
  const { t } = useTranslation();
  const [restaurantName, setRestaurantName] = useState('');
  const [componentReady, setComponentReady] = useState(false);

  useEffect(() => {
    if (componentReady === false) {
      setComponentReady(() => {
        return true;
      });
    }
  }, []);
  useEffect(() => {
    if (componentReady && restaurantName === '') {
      if (!! getRestaurant()) fetchRestaurantDataProcess();
      // fetchRestaurantDataProcess();
    }
  }, [componentReady]);

  const fetchRestaurantDataProcess = async () => {
    const result = await fetchRestaurantData();
    if (result.result === 'OK') {
      setRestaurantName(result.data.name);
      document.title = 'Dodaja - ' + result.data.name;
    }
  };
  return (
    <>
      <div className="logo">
        <img src="/img/svg/logo.svg" />
        <h5 className="mt-2">{restaurantName}</h5>
      </div>
      <ul className={"navbar-nav nav-pills " + (!props.isNavbar ? "menu flex-column mb-auto" : "")}>

        { /** CATEGORIES */
          (props.isAssigned || props.isAdmin) &&
          <>
            {!!props.menu.categories && props.menu.categories.length === 0 && <span className="text-center text-white p-2">{t('noCategoryPresent')}</span>}
            {props.menu.categories && props.menu.categories.map(mealType => (
              (
                (mealType.plates.length > 0 || props.isAdmin) &&
                <MenuElem key={'menu-elem-' + mealType.id + Math.floor(Math.random() * 10000) + new Date().getSeconds()} mealType={mealType} {...props} />
              )
            ))
            }
          </>
        }
        <MenuAdmin {...props} />
      </ul>
      {(props.isAssigned || props.isAdmin) &&
        <hr className="text-white" />
      }
    </>
  );
}
export default MenuList;