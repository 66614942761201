import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import QRCode from 'qrcode.react';
import Cookies from 'js-cookie';
import { listenMessage, showSuccessToast } from '../../services/util';
import { fetchUserSessions } from '../../services/api';

function QrCode(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [hasListened, setHasListened] = useState(false);

  /** Per rimuovere avvisi */
  if (hasListened);

  useEffect(() => {
    props.setPageTo('qrCode');
    setHasListened(function (prevHasListened) {
      if (!prevHasListened) {
        listenMessage(async (rid) => {
          /** Prendo il rid passato per messaggio dal ristoratore, così da poter effettuare le chiamate */
          Cookies.set('rid', rid, { expires: 7 });
          const result = await fetchUserSessions(Cookies.get('userId'));
          if (result.result === 'OK') {
            Cookies.set('sessionId', result.data[0]._id, { expires: 7 });
          }
          props.setIsAssigned(true);
          props.refreshApp();
          showSuccessToast(t('tableAssignedSuccessfully'));
        }, 'qrcode_scan_' + Cookies.get('userId'), 'isAssigned');
        return true;
      }
    });

  }, []);

  const handleRefresh = () => {
    props.setShowLoading(true);
    navigate("/");
    window.location.reload();
  };
  return (
    <div className="container px-5 text-center qrCodeBox">
      <h3 className="pb-4">Chiedi al ristorante di farti assegnare il tavolo tramite questo QR Code:</h3>
      <div className="d-flex justify-content-center align-items-center">
        <QRCode className="d-none d-lg-block" value={Cookies.get('userId')} size="200" />
        <QRCode className="d-lg-none" value={Cookies.get('userId')} size="100" />
      </div>
      <button onClick={() => handleRefresh()} className="w-100 btn btn-lg btn-primary mt-5" type="button">{t('update')}</button>

    </div>
  );
}
export default QrCode;