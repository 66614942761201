// src/translations.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Imposta le traduzioni e la lingua predefinita
const resources = {
  en: {
    translation: {
      'changeMenu': 'Switch menù',
      'edit': 'Edit',
      'orderList': 'Order list',
      'customerList': 'Customer list',
      'tableList': 'Table list',
      'newCategoryPlaceholder': 'New',
      'confirm': 'Confirm',
      'confirmQuestion': 'Confirm?',
      'addNewMeal': 'Add new meal',
      'chooseTheMenu': 'Choose the menù',
      'chooseTheRestaurant': 'Choose the restaurant',
      'newTypeOfMenu': 'New type of menù',
      'menuChangedCorrectly': 'Menù loading completed',
      'menuEditedCorrectly': 'Menù edit completed',
      'clientRemovedCorrectly': 'Client removed correctly',
      'customerAcceptedCorrectly': 'Customer accepted correctly',
      'mealCreatedCorrectly': 'Meal created correctly',
      'EnterAllParameters': 'Enter all parameters',
      'fillAllFields': 'Fill in all fields',
      'mealAlreadyPresent': 'Meal already present',
      'correctlyEditedMeal': 'Correctly edited meal',
      'noCategoryPresent': 'No category present',
      'categoryAddedCorrectly': 'Category added correctly',
      'enterANameForTheCategory': 'Enter a name for the category',
      'categoryAlreadyPresent': 'Category already present',
      'categoryDeletedCorrectly': 'Category deleted correctly',
      'menuDeletedCorrectly': 'Menu deleted correctly',
      'menuAddedCorrectly': 'Menu added correctly',
      'tableCancelledCorrectly': 'Table cancelled correctly',
      'mealCancelledCorrectly': 'Meal cancelled correctly',
      'mealConfirmedCorrectly': 'Meal confirmed correctly',
      'orderCancelledCorrectly': 'Order cancelled correctly',
      'orderConfirmedCorrectly': 'Order confirmed correctly',
      'readyOrders': 'Ready orders',
      'orderReady': 'Order ready',
      'ready': 'Ready',
      'orderCompleted': 'Order completed',
      'completed': 'Completed',
      'ordersCancelledSuccessfully': 'Orders cancelled successfully',
      'ordersConfirmedSuccessfully': 'Orders confirmed successfully',
      'mealsConfirmedSuccessfully': 'Meals confirmed successfully',
      'mealsCompletedSuccessfully': 'Meals completed successfully',
      'tableCreatedSuccessfully': 'Table created successfully',
      'tableAssignedSuccessfully': 'Table assigned successfully',
      'tableClosedSuccessfully': 'Table closed successfully',
      'TheTableWasClosed': 'The table was closed',
      'MessageSentWithSuccess': 'Message sent successfully: we will reply within 24 hours to your email',
      'sendOrderSuccessfully': 'The order was sent correctly',
      'anErrorOccurred': 'An error occurred',
      'anErrorOccurredWhileChangingMenu.': 'An error occurred while changing menù',
      'anErrorOccurredDuringTableDeletion.': 'An error occurred during the table deletion',
      'anErrorOccurredDuringMealDeletion.': 'An error occurred during the meal deletion',
      'anErrorOccurredDuringCustomerRemoval.': 'An error occurred during the customer removal',
      'anErrorOccurredDuringCustomerAcceptance.': 'An error occurred during customer acceptance',
      'anErrorOccurredDuringMealCreation': 'An error occurred during meal creation',
      'anErrorOccurredDuringMealModification': 'An error occurred during meal modification',
      'anErrorOccurredDuringSendOrder': 'There was an error when sending the order',
      'mealAlreadyExists': 'Meal already exists.',
      'enterCategoryName': 'Enter a name for the category',
      'enterNumericValueAsThePrice': 'Enter a numerical value as the price',
      'anErrorOccurredDuringCategoryAddition': 'An error occurred during category addition',
      'anErrorOccurredDuringCategoryModification': 'An error occurred during category modification',
      'anErrorOccurredDuringCategoryDeletion': 'An error occurred during category deletion',
      'anErrorOccurredDuringMenuModification': 'An error occurred during menu modification',
      'enterMenuName': 'Enter the menu name',
      'menuAlreadyExists': 'Menu already exists',
      'anErrorOccurredDuringMenuDeletion': 'An error occurred during menu deletion',
      'anErrorOccurredDuringMenuUpdate': 'An error occurred during menu update',
      'anErrorOccurredDuringMenuAddition': 'An error occurred during menu addition',
      'anErrorOccurredDuringMealConfirmation': 'An error occurred during meal confirmation',
      'anErrorOccurredDuringSelectedOrdersCompletation': 'An error occurred during selected meal completation',
      'anErrorOccurredDuringOrderDeletion': 'An error occurred during order deletion',
      'anErrorOccurredDuringOrderConfirmation': 'An error occurred during order confirmation',
      'anErrorOccurredDuringOrderCompletation': 'An error occurred while completing the order',
      'anErrorOccurredDuringSelectedOrdersConfirmation': 'An error occurred during confirmation of selected orders',
      'anErrorOccurredDuringSelectedMealsDeletion': 'An error occurred during deletion of selected meals',
      'anErrorOccurredDuringTablesLoading': 'An error occurred during tables loading',
      'anErrorOccurredDuringTableCreation': 'An error occurred during table creation',
      'anErrorOccurredDuringTableClosure': 'An error occurred during table closure',
      'anErrorOccurredDuringTableAssignment': 'An error occurred during table assignment',
      'anErrorOccurredDuringSendMessage': 'An error occurred during send message',
      'anErrorOccurredDuringSaveChanges': 'An error occurred during save changes',
      'anErrorOccurredDuringLogin': 'An error occurred during login',
      'anErrorOccurredDuringRegister': 'An error occurred during register',
      'deleteTheSelectedMeal': 'Delete the selected meal?',
      'confirmTableClosing': 'Are you sure about closing the table?',
      'completeSelected': 'Do you complete the selected ones?',
      'confirmSelected': 'Do you confirm the selected ones?',
      'deleteSelected': 'Delete selected?',
      'confirmDeletion': 'Confirm the deletion?',
      'confirmSendMessage': 'Confermi l\'invio del messaggio?',
      'removeCustomer': 'Remove the customer?',
      'confirmDeleteSelectedCategory': 'Confirm the deletion of the selected category?',
      'confirmAction': 'Confirm?',
      'confirmDeleteEntireOrder': 'Confirm the deletion of the entire order?',
      'confirmWholeOrder': 'Confirm the entire order?',
      'scanTheQRCode': 'Scan the QR Code',
      'assign': 'Assign',
      'close': 'Close',
      'undo': 'Undo',
      'order': 'Order',
      'cart': 'Cart',
      'create': 'Create',
      'delete': 'Delete',
      'update': 'Update',
      'deleteOneTable': 'Delete one table',
      'contactUs': 'Contact us',
      'username': 'Username',
      'email': 'Email',
      'password': 'Password',
      'incorrectCredentials': 'Incorrect credentials',
      'newPassword': 'New password',
      'oldPassword': 'Old password',
      'oldPasswordWrong': 'The old password is wrong',
      'confirmPassword': 'Confirm password',
      'save': 'Save',
      'saveChanges': 'Save changes',
      'changesSavedCorrectly': 'Changes saved correctly',
      'passwordAndConfirmDoNotMatch': 'Password and Confirm Password do not match',
      'table': 'Table',
      'signIn': 'Sign In',
      'signInWithFacebook': 'Sign in with Facebook',
      'signInWithGoogle': 'Sign in with Google',
      'priceCeilingExceeded': 'Price ceiling exceeded',
      'TheListIsEmpty': 'The list is empty',
      'register': 'Register',
      'successfulRegistration': 'Successful registration',
      'submit': 'Submit',
      'userAlreadyExists': 'User Already Exists',
      'userNotFound': 'User Not Found',
      'logout': 'Logout',
      'staff': 'Staff',
      'addStaffPlaceholder': 'Contributor\'s email',
      'informative': 'Informative',
      'goBack': 'Go back',
      'tableAlreadyAssigned': 'Table already assigned',
      'addRestaurant': 'Add Restaurant',
      'changePassword': 'Change Password',
      'name': 'Name',
      'address': 'Address',
      'restaurateur': 'Restaurateur',
      'restaurateurEmail': 'Restaurateur Email',
      'add': 'Add',
      'newPasswordEqualsToOld': 'New password is equals to old password',
      'operationCompletedSuccessfully': 'Operation completed successfully',
      'saveCompletedSuccessfully': 'Save successfully completed',
      'errorDuringTheOperation': 'There was an error during the operation',
      'seePreview': 'See Preview',
      'hidePreview': 'Hide Preview',
      'DeletionRequestStatus': 'Your request is',
      'DeletionRequestNotFoundStatus': 'Your request was not found',
      'DeletionRequestPendingStatus': 'pending',
      'DeletionRequestCompletedStatus': 'was completed',
      'ConfirmEmailLinkNotValid': 'Email confirmation link not valid',
      'VerificationLinkNotValidOrExpired': 'Verification link not valid or expired',
      'MaybeEmailAlreadyConfirmed': 'Have you already confirmed the email?',
      'CookieTokenNotValid': 'Missing or invalid Token in Cookie',
      'GoBackToLogin': 'Back to login',
      'GoBackToHome': 'Back to home',
      'VerifyEmailPageExplanation': 'You have been redirected to this page because your email address has not been verified yet. Please check your email, including the spam or junk folder, to find the verification email. If you have not received the email, you can request a new one by clicking the button below.',
      'SendNewVerificationEmail': 'Send new verification email'
    },
  },
  it: {
    translation: {
      'changeMenu': 'Cambia menù',
      'edit': 'Modifica',
      'orderList': 'Lista ordini',
      'customerList': 'Lista clienti',
      'tableList': 'Lista tavoli',
      'newCategoryPlaceholder': 'Nuova',
      'confirm': 'Conferma',
      'confirmQuestion': 'Confermi?',
      'addNewMeal': 'Aggiungi nuovo pasto',
      'chooseTheMenu': 'Scegli il menù',
      'chooseTheRestaurant': 'Scegli il ristorante',
      'newTypeOfMenu': 'Nuovo tipo di menù',
      'menuChangedCorrectly': 'Caricamento del menù completato',
      'menuEditedCorrectly': 'Aggiornamento del menù completato',
      'clientRemovedCorrectly': 'Cliente rimosso correttamente',
      'customerAcceptedCorrectly': 'Cliente accettato correttamente',
      'tableCancelledCorrectly': 'Tavolo cancellato correttamente',
      'mealCancelledCorrectly': 'Pasto cancellato correttamente',
      'mealCreatedCorrectly': 'Pasto creato correttamente',
      'enterAllParameters': 'Inserisci tutti i parametri',
      'fillAllFields': 'Compilare tutti i campi',
      'mealAlreadyPresent': 'Pasto già presente',
      'correctlyEditedMeal': 'Pasto modificato correttamente',
      'noCategoryPresent': 'Nessuna categoria presente',
      'categoryAddedCorrectly': 'Categoria aggiunta correttamente',
      'enterANameForTheCategory': 'Inserisci un nome per la categoria',
      'categoryAlreadyPresent': 'Categoria già presente',
      'correctlyEditedCategory': 'Categoria modificata correttamente',
      'categoryDeletedCorrectly': 'Categoria eliminata correttamente',
      'menuDeletedCorrectly': 'Menù cancellato correttamente',
      'menuAddedCorrectly': 'Menù aggiunto correttamente',
      'mealConfirmedCorrectly': 'Pasto confermato correttamente',
      'orderCancelledCorrectly': 'Ordine cancellato correttamente',
      'orderConfirmedCorrectly': 'Ordine confermato correttamente',
      'readyOrders': 'Ordini pronti',
      'orderReady': 'Ordine pronto',
      'ready': 'Pronto',
      'orderCompleted': 'Ordine completato',
      'completed': 'Completato',
      'ordersCancelledSuccessfully': 'Ordini cancellati correttamente',
      'ordersConfirmedSuccessfully': 'Ordini confermati correttamente',
      'mealsConfirmedSuccessfully': 'Pasti confermati correttamente',
      'mealsCompletedSuccessfully': 'Pasti completati correttamente',
      'tableCreatedSuccessfully': 'Tavolo creato correttamente',
      'tableAssignedSuccessfully': 'Tavolo assegnato con successo',
      'tableClosedSuccessfully': 'Tavolo chiuso correttamente',
      'TheTableWasClosed': 'Il tavolo è stato chiuso',
      'MessageSentWithSuccess': 'Messaggio inviato con successo: ti risponderemo entro 24 ore alla tua email',
      'sendOrderSuccessfully': 'L\'ordine è stato inviato correttamente',
      'anErrorOccurred': 'Si è verificato un errore...',
      'anErrorOccurredWhileChangingMenu': 'Si è verificato un errore durante il cambio menù.',
      'anErrorOccurredDuringCustomerRemoval': 'Si è verificato un errore durante la rimozione del cliente.',
      'anErrorOccurredDuringCustomerAcceptance': 'Si è verificato un errore durante l\'accettazione del cliente.',
      'anErrorOccurredDuringMealCreation.': 'Si è verificato un errore durante la creazione del pasto.',
      'anErrorOccurredDuringMealModification': 'Si è verificato un errore durante la modifica del pasto.',
      'anErrorOccurredDuringSendOrder': 'C\'è stato un\'errore durante l\'invio dell\'ordine',
      'mealAlreadyExists': 'Pasto già presente',
      'enterCategoryName': 'Inserisci un nome per la categoria',
      'enterNumericValueAsThePrice': 'Inserisci un valore numerico come prezzo',
      'anErrorOccurredDuringCategoryAddition': 'Si è verificato un errore durante l\'aggiunta della categoria',
      'anErrorOccurredDuringCategoryModification': 'Si è verificato un errore durante la modifica della categoria',
      'anErrorOccurredDuringCategoryDeletion': 'Si è verificato un errore durante la cancellazione della categoria',
      'anErrorOccurredDuringMenuModification': 'Si è verificato un errore durante la modifica del menù',
      'enterMenuName': 'Inserisci il nome del menù',
      'menuAlreadyExists': 'Menù già presente',
      'anErrorOccurredDuringMenuDeletion': 'Si è verificato un errore durante la cancellazione del menù',
      'anErrorOccurredDuringMenuUpdate': 'Si è verificato un errore durante l\'aggiornamento del menù',
      'anErrorOccurredDuringMenuAddition': 'Si è verificato un errore durante l\'aggiunta del menù',
      'anErrorOccurredDuringTableDeletion.': 'Si è verificato un errore durante la cancellazione del tavolo',
      'anErrorOccurredDuringMealDeletion': 'Si è verificato un errore durante la cancellazione del pasto',
      'anErrorOccurredDuringMealConfirmation': 'Si è verificato un errore durante la conferma del pasto',
      'anErrorOccurredDuringSelectedOrdersCompletation': 'Si è verificato un errore durante il completamento del pasto selezionato',
      'anErrorOccurredDuringOrderDeletion': 'Si è verificato un errore durante la cancellazione dell\'ordine',
      'anErrorOccurredDuringOrderConfirmation': 'Si è verificato un errore durante la conferma dell\'ordine',
      'anErrorOccurredDuringOrderCompletation': 'Si è verificato un errore durante il completamento dell\'ordine',
      'anErrorOccurredDuringSelectedOrdersConfirmation': 'Si è verificato un errore durante la conferma degli ordini selezionati',
      'anErrorOccurredDuringSelectedMealsDeletion': 'Si è verificato un errore durante la cancellazione dei pasti selezionati',
      'anErrorOccurredDuringTablesLoading': 'Si è verificato un errore durante il caricamento dei tavoli',
      'anErrorOccurredDuringTableCreation': 'Si è verificato un errore durante la creazione del tavolo',
      'anErrorOccurredDuringTableClosure': 'Si è verificato un errore durante la chiusura del tavolo',
      'anErrorOccurredDuringTableAssignment': 'Si è verificato un errore durante l\'assegnazione del tavolo',
      'anErrorOccurredDuringSendMessage': 'Si è verificato un errore durante l\'invio del messaggio',
      'anErrorOccurredDuringSaveChanges': 'Si è verificato un errore durante il salvataggio delle modifiche',
      'anErrorOccurredDuringLogin': 'Si è verificato un errore durante il login',
      'anErrorOccurredDuringRegister': 'Si è verificato un errore durante la registrazione',
      'deleteTheSelectedMeal': 'Cancellare il pasto selezionato?',
      'confirmTableClosing': 'Sei sicuro di chiudere il tavolo?',
      'completeSelected': 'Completare i selezionati?',
      'confirmSelected': 'Confermi i selezionati?',
      'deleteSelected': 'Cancellare i selezionati?',
      'confirmDeletion': 'Confermi la cancellazione?',
      'confirmSendMessage': 'Confermi l\'invio del messaggio?',
      'removeCustomer': 'Rimuovere il cliente?',
      'confirmDeleteSelectedCategory': 'Cancellare la categoria selezionata?',
      'confirmAction': 'Confermare?',
      'confirmDeleteEntireOrder': 'Cancellare l\'ordine intero?',
      'confirmWholeOrder': 'Confermi l\'ordine intero?',
      'scanTheQRCode': 'Scannerizza il QR Code',
      'assign': 'Assegna',
      'close': 'Chiudi',
      'undo': 'Annulla',
      'order': 'Ordina',
      'cart': 'Carrello',
      'create': 'Crea',
      'delete': 'Cancella',
      'update': 'Aggiorna',
      'deleteOneTable': 'Elimina un tavolo',
      'contactUs': 'Contattaci',
      'username': 'Username',
      'email': 'Email',
      'password': 'Password',
      'incorrectCredentials': 'Credenziali errate',
      'newPassword': 'Nuova password',
      'oldPassword': 'Vecchia password',
      'oldPasswordWrong': 'La vecchia password è sbagliata',
      'confirmPassword': 'Conferma password',
      'save': 'Salva',
      'saveChanges': 'Salva modifiche',
      'changesSavedCorrectly': 'Modifiche salvate correttamente',
      'passwordAndConfirmDoNotMatch': 'Password e Conferma password devono coincidere',
      'table': 'Tavolo',
      'signIn': 'Entra',
      'signInWithFacebook': 'Entra con Facebook',
      'signInWithGoogle': 'Entra con Google',
      'priceCeilingExceeded': 'Limite massimo di prezzo superato',
      'TheListIsEmpty': 'La lista è vuota',
      'register': 'Registrati',
      'successfulRegistration': 'Registrazione effettuata con successo',
      'submit': 'Invia',
      'userAlreadyExists': 'L\'utente è già registrato',      
      'userNotFound': 'Utente non trovato',
      'logout': 'Logout',
      'staff': 'Staff',
      'addStaffPlaceholder': 'Email del collaboratore',
      'informative': 'Informativa',
      'goBack': 'Torna indietro',
      'tableAlreadyAssigned': 'Tavolo già assegnato',
      'addRestaurant': 'Aggiungi Ristorante',
      'changePassword': 'Cambia Password',
      'name': 'Nome',
      'address': 'Indirizzo',
      'restaurateur': 'Ristoratore',
      'restaurateurEmail': 'Email Ristoratore',
      'add': 'Aggiungi',
      'newPasswordEqualsToOld': 'La nuova password è uguale alla vecchia',
      'operationCompletedSuccessfully': 'Operazione effettuata correttamente',
      'saveCompletedSuccessfully': 'Salvataggio effettuato correttamente',
      'errorDuringTheOperation': 'C\'è stato un errore durante l\'operazione',
      'seePreview': 'Vedi anteprima',
      'hidePreview': 'Nascondi anteprima',
      'DeletionRequestStatus': 'La tua richiesta è',
      'DeletionRequestNotFoundStatus': 'La tua richiesta non è stata trovata',
      'DeletionRequestPendingStatus': 'in corso',
      'DeletionRequestCompletedStatus': 'stata completata',
      'ConfirmEmailLinkNotValid': 'Link di verifica email non valido',
      'CookieTokenNotValid': 'Token del cookie non valido o mancante',
      'VerificationLinkNotValidOrExpired': 'Link di verifica non valido o scaduto.',
      'MaybeEmailAlreadyConfirmed': 'Forse hai già confermato la mail?',
      'GoBackToLogin': 'Torna al login',
      'GoBackToHome': 'Torna alla home',
      'VerifyEmailPageExplanation': `Sei stato reindirizzato a questa pagina perché il tuo indirizzo email non è stato ancora verificato. Controlla la tua email, inclusa la cartella spam o posta indesiderata, per trovare l'email di verifica. Se non hai ricevuto l'email, puoi richiederne una nuova cliccando il pulsante sottostante.`,
      'SendNewVerificationEmail': 'Send new verification email'
    }
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it', // Lingua predefinita
    interpolation: {
      escapeValue: false, // Non escapa i valori di default
    },
  })
  .then(() => {
    // Forza manualmente l'aggiornamento della lingua
    i18n.changeLanguage(navigator.language);
  });;

export default i18n;
