
import { toast, Zoom } from 'react-toastify';

import Cookies from 'js-cookie';
import { logoutFetch } from './api';

// const WS_SERVER = 'ws://ayce-ws-7qouqbu2da-ew.a.run.app/';
const WS_SERVER = process.env.REACT_APP_WS_SERVER;

//const WS_SERVER = 'ws://localhost:3001/';

export const listenMessage = async (callbackFunction, clientId, messageToCheck, closeConnection = true) => {
    return new Promise((resolve, reject) => {
        const ws = new WebSocket(WS_SERVER);

        ws.onopen = () => {/*  */
            ws.send(JSON.stringify({
                // "type": "frontend", clientId: 'qrcode_scan_' + Cookies.get('userId')
                "type": "frontend", clientId
            }));
        };

        ws.onerror = (error) => {
            reject(error);
        };

        ws.onmessage = async (message) => {
            message = JSON.parse(message.data);
            // if (message.msg === 'isAssigned') {
            if (message.msg === messageToCheck) {

                if (!!message.other) {
                    callbackFunction.apply(null, [message.other]);
                } else {
                    callbackFunction.apply();
                }


                if (closeConnection === true) ws.close();
                else {
                    // Aggiungi un listener per l'evento beforeunload per chiudere il WebSocket
                    const closeWebSocket = () => {
                        if (ws.readyState === WebSocket.OPEN) {
                            ws.close();
                        }
                    };

                    window.addEventListener('beforeunload', closeWebSocket);

                    // Rimuovi il listener quando la connessione viene chiusa
                    ws.onclose = () => {
                        window.removeEventListener('beforeunload', closeWebSocket);
                    };
                }
            };
        };
    });
};
export const sendMessage = async (toClientId, messageToSend, other = false) => {
    return new Promise((resolve, reject) => {
        const ws = new WebSocket(WS_SERVER);
        ws.onopen = () => {
            var objToSend = {
                msg: messageToSend, toClientId
            }
            if (other !== false) objToSend['other'] = other;
            ws.send(JSON.stringify(objToSend));
            ws.close();
        };

        ws.onerror = (error) => {
            reject(error);
        };
    });
};
export const fetchPostProcess = async (awaitFunction, okFunction, successMessage, errorMessage, props) => {
    try {
        props.setShowLoading(true);
        const postFetch = await awaitFunction();
        if (postFetch.result === 'OK') {
            okFunction();
            showSuccessToast(successMessage);
        } else {
            fullError(errorMessage, postFetch);
        }
    } catch (error) {
        fullError(errorMessage, error);
    } finally {
        props.setShowLoading(false);
    }
}

export const getOrderIdByLongId = (longId) => {
    const indexOfHyphen = longId.indexOf('-');

    if (indexOfHyphen !== -1) {
        // Se è presente un trattino, restituisci il testo dopo di esso
        return longId.substring(indexOfHyphen + 1);
    } else {
        // Se non c'è nessun trattino, restituisci una stringa vuota
        return "";
    }
}

export const fullError = (text, errorObj = false) => {
    console.error(text, errorObj);
    showDangerToast(text);
}
export const showDangerToast = (message, autoClose = 1500) => {
    toast.error(message, {
        position: "top-center",
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
    })
}
export const showSuccessToast = (message, autoClose = 1500) => {
    toast.success(message, {
        position: "top-center",
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
    })
}
export const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

export const logout = async () => {
    const resultFetch = await logoutFetch();
    Cookies.remove('userId');
    Cookies.remove('username');
    Cookies.remove('rid');
    localStorage.removeItem('isAdmin');
    Cookies.remove('xAuthToken');
    Cookies.remove('jwtrt');
    Cookies.remove('isLoggedIn');
    localStorage.removeItem('menuTypeSelected');
    window.location.reload();
}

export const getHour =  (timestamp) => {
    const dateString = timestamp;
    const dateObject = new Date(dateString);

    const formattedTime = new Intl.DateTimeFormat('it-IT', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'Europe/Rome', // Fuso orario dell'Italia
    }).format(dateObject);

    return formattedTime;
}
