// PublicRoutes.js
import {Route} from 'react-router-dom';
import DeletionRequestStatus from "../pages/externalLogins/DeletionRequestStatus";
import React from "react";
import ConfirmEmailPage from "../pages/externalLogins/ConfirmEmail";

export const PublicRoutes = (setIsRestaurantsModalOpen, setIsEmailToVerify, setIsLoggedIn, isLoggedIn) => {
    /*ROUTE PUBBLICHE, ACCESSIBILI SEMPRE, GRAFICAMENTE INDIPENDENTI*/
    return <>
        <Route path="/data-deletion/confirmation" element={<DeletionRequestStatus />} />
        <Route path="/confirmEmail/:token" element={ <ConfirmEmailPage setIsRestaurantsModalOpen={setIsRestaurantsModalOpen} setIsEmailToVerify={setIsEmailToVerify} setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn}/>}/>
    </>
};