import React from 'react';
import { useTranslation } from 'react-i18next';

const FacebookLoginButton = () => {
    const { t } = useTranslation();
    const facebookAuth = () => {
        const clientId = process.env.REACT_APP_FACEBOOK_OAUTH_APP_ID;
        const redirectUri = `${process.env.REACT_APP_BACKEND_URL}/login/facebook`;
        //const state = 'your-csrf-token'; // CSRF protection
        const scope = 'email'; // the scopes you need
        window.location = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`; //&state=${state}
    }

    return (
        <button type="button" className="btn btn-lg btn-primary mb-3" onClick={facebookAuth}>
            <i className="bi bi-facebook float-start ms-2"></i>{t('signInWithFacebook')}
        </button>
    );
};

export default FacebookLoginButton;