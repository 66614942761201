import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Login.css';
import App from './App';
import Login from './Login';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Cookies from 'js-cookie';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {PublicRoutes} from "./components/routes/PublicRoutes";


const RootComponent = () => { 
  const [isLoggedIn, setIsLoggedIn] = useState(!!(Cookies.get('xAuthToken')) && Cookies.get('isLoggedIn') === 'true');
  const [isEmailToVerify, setIsEmailToVerify] = useState(!!(Cookies.get('isEmailToVerify')));

  const setIsEmailToVerifyCookie = (value) => {
    setIsEmailToVerify(value);
    if(!value){
      Cookies.remove('isEmailToVerify');
      Cookies.remove('nextResendAt');
    } else {
      Cookies.set('isEmailToVerify', value);
    }
  };

    /** Modals */
    const [isRestaurantsModalOpen, setIsRestaurantsModalOpen] = useState(false);

  return (
      <React.StrictMode>
        <Router>
          <Routes>
            {PublicRoutes(setIsRestaurantsModalOpen, setIsEmailToVerifyCookie, setIsLoggedIn,isLoggedIn)}
            <Route path="/*" element={
              isLoggedIn
                  ? <App />
                  : <Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} setIsEmailToVerify={setIsEmailToVerifyCookie} isEmailToVerify={isEmailToVerify} isRestaurantsModalOpen={isRestaurantsModalOpen} setIsRestaurantsModalOpen={setIsRestaurantsModalOpen}/>
            } />
          </Routes>
        </Router>
      </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
