import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const RegisterForm = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const [opened, setOpened] = useState(false);

    const registerProcess = async () => {
        if (await props.register() === true) {
            navigate('/');
        };
    }
    const handleKeyUpRegister = (event) => {
        if (event.key === 'Enter') {
            registerProcess();
        }
    }
    // const iubenda = (event) => {
    //     (function (w, d) {
    //         var loader = function () {
    //             var s = d.createElement("script"),
    //                 tag = d.getElementsByTagName("script")[0]; s.src = "https:cdn.iubenda.com/iubenda.js";
    //             tag.parentNode.insertBefore(s, tag);
    //         }; if (w.addEventListener) { w.addEventListener("load", loader, false); }
    //         else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; }
    //     })(window, document);

    // }

    return (
        <div className="registerForm">
            <h3>{t('register')}</h3>
            <div className="form-floating">
                <input onChange={(event) => props.setEmailValue(event.target.value)} type="text"
                    className="form-control" id="floatingInput"
                    placeholder={t('email')} />
                <label htmlFor="floatingInput">{t('email')}</label>
            </div>
            <div className="form-floating">
                <input
                    onChange={(event) => props.setPasswordValue(event.target.value)} type="password" className="form-control floatingPassword floatingPasswordRegister"
                    id="floatingPassword" placeholder={t('password')} />
                <label htmlFor="floatingPassword">{t('password')}</label>
            </div>
            <div className="form-floating">
                <input onKeyUp={handleKeyUpRegister}
                    onChange={(event) => props.setConfirmPasswordValue(event.target.value)} type="password"
                    className="form-control floatingConfirmPasswordRegister" id="floatingConfirmPassword" placeholder={t('confirmPassword')} />
                <label htmlFor="floatingConfirmPassword">{t('confirmPassword')}</label>
            </div>
            <div className="hero-checkbox">

                <input onChange={(event) => props.setConsentValue(event.target.checked)} name="consent" className="consent" type="checkbox" required />
                <p className="consent-text">Acconsento al trattamento dei miei dati personali in conformità
                    alle finalità della&nbsp;&nbsp;
                    <a target="_blank" href="https://www.iubenda.com/privacy-policy/81740317"
                        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe text-white "
                        title="Privacy Policy ">Privacy Policy</a>
                </p>

            </div>
            <button onClick={registerProcess} className="w-100 btn btn-lg btn-primary mb-3" type="button">{t('submit')}</button>
            <a href="/" className="w-100 btn btn-lg btn-secondary mb-3">{t('goBack')}</a>
        </div>
    );
};

export default RegisterForm;