import React, { useState } from 'react';
/** Bootstrap */
import { Modal, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import { getRestaurant, sendOrderFetch } from '../../services/api';

import { sendMessage, showDangerToast, showSuccessToast } from '../../services/util';

import { useNavigate } from 'react-router-dom';

import Cart from '../pages/Cart';

const CartModal = (props) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const sendOrder = async () => {
        if (await window.confirm(t('confirm'))) {
            var plates = [];
            props.cart.forEach(meal => {
                plates.push({
                    id: meal.meal.id,
                    amount: meal.quantity
                });
            });
            try {
                props.setShowLoading(true);

                const result = await sendOrderFetch(plates, props.menuType._id);

                if (result.result === 'OK') {
                    props.setCart([]);
                    props.resetMeals();
                    sendMessage('order_sent_' + getRestaurant(), 'orderSent');

                    showSuccessToast(t('sendOrderSuccessfully'));

                    props.setIsCartModalOpen(false);

                    navigate('/');
                } else showDangerToast(t('anErrorOccurredDuringSendOrder'));


            } catch (error) {
                console.error(t('anErrorOccurredDuringSendOrder'), error);
                showDangerToast(t('anErrorOccurredDuringSendOrder'));
            } finally {
                props.setShowLoading(false);
            }
        }
    }
    return (
        <Modal className="modal" show={props.isCartModalOpen} onHide={() => props.setIsCartModalOpen(false)} centered size="lg">
            <Modal.Header>
                <Modal.Title>{t('cart')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-0 px-sm-2">
                <Cart setShowLoading={props.setShowLoading} page={props.page}
                    setPage={props.setPage} menuType={props.menuType} cart={props.cart} resetMeals={props.resetMeals}
                    setCart={props.setCart} setPageTo={props.setPageTo}
                    changeQuantityToCart={props.changeQuantityToCart} />
            </Modal.Body>
            <Modal.Footer>
                <div className="buttonBox me-sm-2 mb-2 w-100">
                    {props.page == 'cart' &&
                        <button onClick={() => sendOrder()} className="btn btn-primary w-100 d-flex justify-content-center align-items-center cartOrderButton">
                            {t('order')}
                        </button>
                    }
                </div>
                <Button className="w-100" variant="secondary" onClick={() => props.setIsCartModalOpen(false)}>
                    Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CartModal;